import { EditorSDK, EditorType, EventType } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export const editorAppEventListener = async (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  await editorSDK.addEventListener('appActionClicked', (event) => {
    const { actionId } = event.detail;
    switch (actionId) {
      case 'addNewProject':
        editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/project',
          closeOtherPanels: false,
        });
        break;
      case 'manageProjects':
        editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/projects',
          closeOtherPanels: false,
        });
        break;
      case 'addNewCategory':
        editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/category',
          closeOtherPanels: false,
        });
        break;
    }
  });
  // event for delete app page -- for dev work only
  await editorSDK.addEventListener(
    'deletePortfolioPage' as EventType,
    (event) => {
      const { pageRef } = event.detail;
      editorSDK.pages.remove('', {
        pageRef,
        shouldShowEditorRemovePanel: false,
      });
    },
  );
  // event for delete app -- for dev work only
  await editorSDK.addEventListener(
    'deletePortfolioApp' as EventType,
    async (event) => {
      await editorSDK.application.uninstall('', { openConfirmation: true });
    },
  );

  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      const { preset, componentRef } = event.detail;
      let selectedProjectTemplate = '1';
      switch (preset) {
        case 'externalPreset-Template1':
          selectedProjectTemplate = '1';
          break;
        case 'externalPreset-Template2':
          selectedProjectTemplate = '2';
          break;
        case 'externalPreset-Template3':
          selectedProjectTemplate = '3';
          break;

        default:
          break;
      }

      await editorSDK.application.appStudioWidgets.props.set('', {
        widgetRef: componentRef,
        newProps: { data: { projectTemplate: selectedProjectTemplate } },
        shouldFetchData: true,
      });
    },
  );
};
