import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../editor-app/manifest/getRole';
import { ProjectPageWidgetPresets } from './config/constants';
import { ProjectHeaderWidgetComponentIds } from '../projectHeaderWidget/config/constants';
import { ProjectWidgetComponentIds } from '../projectWidget1/config/constants';
import {} from '../../utils';

export const getWidgetManifest: GetWidgetManifestFn = async (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: 'Project Page Widget',
    nickname: 'projectPageWidget',
  });

  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Project_Page_Widget_Gfpp_Main_Action_1'),
      onClick: () => {
        void editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/projects',
          closeOtherPanels: false,
        });
      },
    })
    .set('mainAction2', {
      label: t('Project_Page_Widget_Gfpp_Main_Action_2'),
      onClick: () => {
        void editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/project',
          closeOtherPanels: false,
        });
      },
    })
    .set('layout', { behavior: 'HIDE' });
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    const designBuilder = widgetDesignBuilder.set({
      title: t('Project_Page_Widget_Design_Panel_Title'),
      presetsTitle: t('Project_Page_Widget_Design_Panel_Preset_Title'),
    });

    const widgetDesignTabsBuilder = designBuilder.tabs();
    designBuilder.setPresets(ProjectPageWidgetPresets);
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Title',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Title].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'SubTitle',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.SubTitle].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Description',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Description].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'HeroImage',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.HeroImage].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Gallery',
          })
          .groups()
          .set({
            roles: [ProjectWidgetComponentIds.Gallery].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Navigation',
          })
          .groups()
          .set({
            roles: [ProjectWidgetComponentIds.NavigationWidget].map(getRole),
          });
      });
  });
};
