import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../editor-app/manifest/getRole';
import { PortfolioPageWidgetComponentIds } from './config/constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: 'Portfolio Page Widget',
    nickname: 'portfolioPageWidget',
  });

  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_1'),
      onClick: () => {
        void editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/categories',
          closeOtherPanels: false,
        });
      },
    })
    .set('mainAction2', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_2'),
      onClick: () => {
        void editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/projects',
          closeOtherPanels: false,
        });
      },
    })
    .set('layout', { behavior: 'HIDE' });

  // define widget design panel
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('Portfolio_Page_Widget_Design_Panel_Title'),
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Main',
          })
          .groups()
          .set({
            roles: [PortfolioPageWidgetComponentIds.Root].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'MultiState',
          })
          .groups()
          .set({
            roles: [PortfolioPageWidgetComponentIds.MultiState].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Gallery',
          })
          .groups()
          .set({
            roles: [PortfolioPageWidgetComponentIds.GalleryWidget].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'List',
          })
          .groups()
          .set({
            roles: [PortfolioPageWidgetComponentIds.ListWidget].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Category List',
          })
          .groups()
          .set({
            roles: [PortfolioPageWidgetComponentIds.CategoryWidget].map(
              getRole,
            ),
          });
      });
  });
};
