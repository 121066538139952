import { EditorSDK, EditorType } from '@wix/platform-editor-sdk';
import type { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { handleFirstInstall } from './first-install/handleFirstInstall';
import { setPortfolioPagesState } from './first-install/utils/setPortfolioPagesState';
import { editorAppEventListener } from './editorAppEventListener';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export const onEditorReady = async (
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  // if (options.firstInstall) {
  // await handleFirstInstall(editorSDK, appDefId, options, flowAPI);
  // }
  setPortfolioPagesState(editorSDK);
  await editorAppEventListener(editorSDK, flowAPI);
};
